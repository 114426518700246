export const validateData = (type, value) => {
  switch (type) {
    case "dateAfter1800": {
      const dateAfter1800 = /\b(19|[2-9][0-9])\d{2}-([0|1])\d-([0-3])\d\b/;
      return dateAfter1800.test(value);
    }
    case "email": {
      const emailRex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRex.test(value);
    }
    case "number": {
      const numberRex = new RegExp("^[0-9]+$");
      return numberRex.test(value);
    }
    case "integer": {
      const integerRex = /^[-+]?\d*$/;
      return integerRex.test(value);
    }
    case "float": {
      const floatRex = /^(?:\d*\.\d{1,2}|\d+)$/;
      return floatRex.test(value);
    }
    case "alphaNum": {
      const alphanumRex = /^[a-zA-Z0-9]{1,50}$/;
      return alphanumRex.test(value);
    }
    case "string": {
      const string = /^.{1,250}$/;
      return string.test(value);
    }
    case "state": {
      const stateRex = new RegExp("^[ A-Za-z]{2,30}$");
      return stateRex.test(value);
    }
    case "pincode": {
      const pincode = /^(\d{6})$/;
      return pincode.test(value);
    }
    case "ifsc": {
      const ifsc = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/;
      return ifsc.test(value);
    }
    case "gstin": {
      const gstin =
        /^([0][1-9]|[1-2][0-9]|[3][0-8]|[9][79])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
      return gstin.test(value);
    }
    case "tin": {
      const tin = /^[0-9]{11}$/;
      return tin.test(value);
    }
    case "cin": {
      const cin = /^[a-zA-Z0-9]{21}$/;
      return cin.test(value);
    }
    case "mobile": {
      const mobile = /^(\d{10})$/;
      return mobile.test(value);
    }
    case "phone": {
      const phoneRex = new RegExp("^[0-9]{10,11}$");
      return phoneRex.test(value);
    }
    case "mobile": {
      const mobileRex = new RegExp("^[0-9]{10}$");
      return mobileRex.test(value);
    }
    case "pan": {
      const pan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
      return pan.test(value);
    }
    case "address": {
      const nameRex = new RegExp("^[-_ . , @ a-zA-Z0-9]{10,150}$");
      return nameRex.test(value);
    }
    case "alpha": {
      const alphaRex = new RegExp("^[ A-Za-z]{1,250}$");
      return alphaRex.test(value);
    }
    case "mmddyyyy": {
      const date = /^(\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$)/;
      return date.test(value);
    }
    case "boolean": {
      const string = /^(true|false)$/;
      return string.test(value);
    }
    case "booleanNum": {
      const string = /^(0|1)$/;
      return string.test(value);
    }
    case "url": {
      try {
        new URL(value);
        return true;
      } catch (_) {
        return false;
      }
    }

    case "email": {
      const email =
        /^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/i;
      return email.test(value);
    }
    case "aadhaar": {
      const aadhaar = /^\d{12}$/;
      return aadhaar.test(value);
    }
    case "date": {
      const date = /^\d{4}-\d{2}-\d{2}$/;
      return date.test(value);
    }
    case "dob": {
      const dob = /^(\d{4}-\d{2}-\d{2})|(\d{2}-\d{2}-\d{4}$)/;
      return dob.test(value);
    }
    case "float": {
      const float = /^[+-]?\d+(\.\d+)?$/;
      return float.test(value);
    }
    case "passport": {
      const passport = /^[A-Z][0-9]{7}$/;
      return passport.test(value);
    }
    case "number": {
      const number = /^[0-9]*$/;
      return number.test(value);
    }
    case "gst": {
      const gst =
        /^([0][1-9]|[1-2][0-9]|[3][0-5])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
      return gst.test(value);
    }
    case "driving": {
      const driving = /^([A-Z]{2}[0-9]{2}\s[0-9]{11})+$/;
      return driving.test(value);
    }
    case "epic": {
      const epic = /^([a-zA-Z]){3}([0-9]){7}?$/;
      return epic.test(value);
    }
    case "ack": {
      const ack = /^([0-9]){15}$/;
      return ack.test(value);
    }
    case "uan": {
      const uan = /^([0-9]){12}$/;
      return uan.test(value);
    }
    case "vpa": {
      const vpa = /^\w+.\w+@\w+$/;
      return vpa.test(value);
    }
    case "twodigit": {
      const twodigit = /^\d{2}$/;
      return twodigit.test(value);
    }
    case "alpha": {
      const alpha = /^[A-Za-z\s]{1,250}$/;
      return alpha.test(value);
    }
    case "singleAlpha": {
      const singleAlpha = /^[A-Z\s]{1}$/;
      return singleAlpha.test(value);
    }
    case "consent": {
      const consent = /^\w{1}$/;
      return consent.test(value);
    }
    case "consumerid": {
      const consumerid = /^\d{12}/;
      return consumerid.test(value);
    }
    case "timestamp": {
      const timestamp = /^(\d{10})$/;
      return timestamp.test(value);
    }
    case "txntype": {
      const txntype =
        /^(overdue|interest|pf|usage|repayment|manage|emi|bounce*)$/;
      return txntype.test(value);
    }
    case "bounce": {
      const bounce = /^(bounce*)$/;
      return bounce.test(value);
    }
    case "emi": {
      const emi = /^(emi*)$/;
      return emi.test(value);
    }
    case "manage": {
      const manage = /^(manage*)$/;
      return manage.test(value);
    }
    case "repayment": {
      const repayment = /^(repayment*)$/;
      return repayment.test(value);
    }
    case "usage": {
      const usage = /^(usage*)$/;
      return usage.test(value);
    }
    case "pf": {
      const pf = /^(pf*)$/;
      return pf.test(value);
    }
    case "interest": {
      const interest = /^(\d{1,8})(.\d{1,4})?(A|P)$/;
      return interest.test(value);
    }
    case "overdue": {
      const overdue = /^(overdue*)$/;
      return overdue.test(value);
    }
    case "txnentry": {
      const txnentry = /^(cr|dr*)$/;
      return txnentry.test(value);
    }
    case "usageTxnentry": {
      const dr = /^(dr*)$/;
      return dr.test(value);
    }
    case "repayTxnentry": {
      const cr = /^(cr*)$/;
      return cr.test(value);
    }
    case "decimalUARAUPRP": {
      const decimalUARAUPRP = /^(\d{1,8})(.\d{1,4})?(UA|RA|UP|RP)$/;
      return decimalUARAUPRP.test(value);
    }
    case "decimalRARP": {
      const decimalRARP = /^(\d{1,8})(.\d{1,4})?(RA|RP)$/;
      return decimalRARP.test(value);
    }
    case "decimalUAUP": {
      const decimalUAUP = /^(\d{1,8})(.\d{1,4})?(UA|UP)$/;
      return decimalUAUP.test(value);
    }
    case "decimalAP": {
      const decimalAP = /^(\d{1,8})(.\d{1,4})?(A|P)$/;
      return decimalAP.test(value);
    }
    case "arrayLength3": {
      return value.length >= 3;
    }
    case "pattern": {
      const pattern =
        /^(ONETIME|MONTHLY|WEEKLY|DAILY|QUARTERLY|BI-MONTHLY|FORTNIGHTLY|HALFYEARLY|YEARLY|ASPRESENTED)$/;
      return pattern.test(value);
    }
    case "boolean": {
      const boolean = /^(true|false)$/;
      return boolean.test(value);
    }
    default: {
      return true;
    }
  }
};

export const defaultDataValue = item => {
  switch (item?.condition) {
    case "array":
      return [];
    case "checkbox":
      return 0;
    case "boolean":
      return false;
    case "string":
      return "";
    case "object":
      return {};
    default:
      return "";
  }
};
