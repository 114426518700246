import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import SignUpForm from "../components/SignUp";

const SignUp = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />
      <SignUpForm />
      <Footer FooterData={FooterData} />
    </div>
  );
};
export default SignUp;
