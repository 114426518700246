import React, { useState } from "react";
const SignupTypeSelect = props => {
  const { onTypeChange, type } = props;
  return (
    <div
      className="col-lg-12 skipPLPR"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}
    >
      <div
        class="btn-group col-sm-12 skipPLPR mb_20"
        role="group"
        aria-label="Basic example"
      >
        <div
          class={`form-check col-sm-4 firm-type-selector ${
            type === "fintech" ? "selected-firm-type-selector" : ""
          }`}
        >
          <label class="form-check-label">
            <input
              type="checkbox"
              for="fintech"
              class="form-check-input"
              name="fintech"
              onChange={onTypeChange}
              checked={type === "fintech"}
            />
            Fintech
          </label>
        </div>
        <div
          class={`form-check col-sm-4 firm-type-selector ${
            type === "lender" ? "selected-firm-type-selector" : ""
          }`}
        >
          <label class="form-check-label">
            <input
              type="checkbox"
              for="lender"
              class="form-check-input"
              name="lender"
              onChange={onTypeChange}
              checked={type === "lender"}
            />
            Lender
          </label>
        </div>
        <div
          class={`form-check col-sm-4 firm-type-selector ${
            type === "indivisual" ? "selected-firm-type-selector" : ""
          }`}
        >
          <label class="form-check-label">
            <input
              type="checkbox"
              for="indivisual"
              class="form-check-input"
              name="indivisual"
              onChange={onTypeChange}
              checked={type === "indivisual"}
            />
            Indivisual
          </label>
        </div>
      </div>
    </div>
  );
};

export default SignupTypeSelect;
