import React, { useEffect, useState } from "react";
import SeoTitle from "../Title/SeoTitle";
import Fade from "react-reveal/Fade";
import axios from "axios";
import { Link, NavLink, useParams } from "react-router-dom";

const BureauPullList = () => {
  const { alaid } = useParams();

  const [products, setProducts] = useState({});
  useEffect(() => {
    fetchProducts();
  }, [alaid]);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `http://localhost:4003/api/application_onboarding/${alaid}`
      );
      console.log(response.data);
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <React.Fragment>
      <section className="seo_service_area sec_pad">
        <div className="container">
          <SeoTitle Title="Bureau Pull" />
          <div className="row seo_service_info">
            <Fade bottom duration={500}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img src={require("../../img/seo/b2b_icon.png")} alt="" />
                  <a href=".#">
                    <h4>
                      {/* <Link
                        className={`navbar-brand`}
                        to={`/booklead/${item.company_id}/${item._id}`}
                      > */}
                      {products.first_name} {products.last_name}
                      {/* </Link> */}
                    </h4>
                  </a>

                  <p>
                    Hello Customer, Click on link below to apply for loan and
                    check eligibility.
                  </p>
                  {/* <Link
                    className={`navbar-brand`}
                    to={`/booklead/${item.company_id}/${item._id}`}
                  >
                    <i className="arrow_right"></i>
                  </Link> */}
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default BureauPullList;
