import React from "react";

const AccountAggregatorForm = () => {
  return (
    <section className="sign_in_area bg_color sec_pad">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <h2>Account Aggregator</h2>
            <div className="form-group text_box">
              <label className="f_p text_c f_400">Name</label>
              <input
                // onChange=
                type="text"
                placeholder="Name"
                name="name"
                // value=
                className="form-control"
              />
            </div>
            <div className="form-group text_box">
              <label className="f_p text_c f_400">Email</label>
              <input
                // onChange=
                type="text"
                placeholder="Email"
                name="bank_address"
                // value=
                className="form-control"
              />
            </div>
            <div className="form-group text_box">
              <label className="f_p text_c f_400">Mobile Number</label>
              <input
                // onChange=
                type="text"
                placeholder="Mobile Number"
                name="city"
                // value=
                className="form-control"
              />
            </div>
            <button type="submit" className="btn_three">
              Generate OTP
            </button>
            <div className="form-group text_box mt-5">
              <label className="f_p text_c f_400">Verify OTP</label>
              <input
                // onChange=
                type="text"
                placeholder="Mobile Number"
                name="city"
                // value=
                className="form-control"
              />
            </div>
            <button type="submit" className="btn_three">
              Verify OTP
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountAggregatorForm;
