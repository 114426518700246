import React from "react";

import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  TextField
} from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import CustomDropdown from "../Custom/CustomDropdown";
import { EligibilityCheckData } from "./EligibilityCheckData";

const EligibilityCheckFormField = props => {
  const {
    title,
    fieldDepartmentMapper,
    onTextChanged,
    onDDChanged,
    onCheckBoxChanged,
    stateData,
    validationData
  } = props;

  const getItemFromFields = field => {
    return fieldDepartmentMapper[title].items.filter(
      item => item.field === field
    )[0];
  };

  return (
    <Card
      id={title ? title.replace(/\s/g, "-") : "base-"}
      sx={{ overflow: "visible" }}
    >
      <Box p={3}>
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Box component="form" pb={3} px={3}>
        {validationData && (
          <Grid container spacing={3}>
            {fieldDepartmentMapper &&
              fieldDepartmentMapper[title]?.fields.map((item, index) => {
                const currentItem = getItemFromFields(item);
                const currentField = `${currentItem.field}__type__${currentItem.type}__component__${currentItem.component}`;
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={5}
                    key={`${index}--${title}`}
                  >
                    <Grid xs={12} item key={currentField}>
                      <span>{currentItem.title}</span>

                      <FormControl
                        sx={{
                          m: 1,
                          width: "100%"
                        }}
                        variant="standard"
                      >
                        {currentItem.component.toLowerCase() === "text" && (
                          <>
                            <TextField
                              variant="standard"
                              type="text"
                              error={
                                validationData[`${currentField}State`] ===
                                "has-danger"
                              }
                              helperText={
                                validationData[`${currentField}State`] ===
                                "has-danger"
                                  ? currentItem.validationmsg
                                  : ""
                              }
                              placeholder={currentItem.title}
                              value={stateData[`${currentField}`]}
                              onChange={e => {
                                onTextChanged(e, currentItem, currentField);
                              }}
                            />
                          </>
                        )}
                        {currentItem.component.toLowerCase() === "dropdown" && (
                          <>
                            <CustomDropdown
                              name={`${currentField}`}
                              placeholder={currentItem.title}
                              data={EligibilityCheckData[currentItem.dataset]}
                              value={stateData[currentItem.field]}
                              disabled={false}
                              id={currentItem.field}
                              handleDropdownChange={value => {
                                onDDChanged(value, currentItem, currentField);
                              }}
                              helperText={
                                validationData[`${currentField}State`] ===
                                "has-danger"
                                  ? currentItem.validationmsg
                                  : ""
                              }
                            />
                            {
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {validationData[`${currentField}State`] ===
                                "has-danger"
                                  ? currentItem.validationmsg
                                  : ""}
                              </span>
                            }
                          </>
                        )}
                        {currentItem.component.toLowerCase() === "checkbox" && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={`${currentField}`}
                                disabled={false}
                                checked={stateData[currentItem.field]}
                                onChange={e => {
                                  onCheckBoxChanged(
                                    e,
                                    currentItem,
                                    currentField
                                  );
                                }}
                              />
                            }
                            label={currentItem.title}
                          />
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        )}
      </Box>
    </Card>
  );
};

export default EligibilityCheckFormField;
