import React from "react";
import BannerData from "../components/Banner/TermsOfUseBannerData";
import TermsOfUseBanner from "../components/Banner/TermsOfUseBanner";
import CustomNavbar from "../components/CustomNavbar";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import TermsOfUse from "../components/Links/TermsOfUse";

const Terms = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />
      <TermsOfUseBanner BannerData={BannerData} />
      <TermsOfUse />
      <Footer FooterData={FooterData} />
    </div>
  );
};
export default Terms;
