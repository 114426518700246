import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { INDIVIDUAL_FIELDS } from "./individualfields";
import { defaultDataValue } from "../../util/validation";
import { validateData } from "../../util/validation";
const IndividualSignup = (props) => {
  const [bearerToken, setBearerToken] = useState(process.env.API_BEARER_TOKEN);
  const [stateData, setStateData] = useState({});
  const [stateErrorState, setStateErrorState] = useState({});

  useEffect(() => {
    clearOrGenerateFields();
  }, []);

  const clearOrGenerateFields = () => {
    const stateDataCopy = { ...stateData };
    const stateErrorStateCopy = { ...stateErrorState };
    INDIVIDUAL_FIELDS.forEach((item) => {
      stateDataCopy[`${item.name}`] = defaultDataValue(item);
      stateErrorStateCopy[`${item.name}State`] = "";
    });
    setStateData(stateDataCopy);
    setStateErrorState(stateErrorStateCopy);
  };

  const validateStateData = () => {
    const stateErrorStateCopy = { ...stateErrorState };
    const errorFields = [];
    const validFields = [];
    INDIVIDUAL_FIELDS.forEach((data) => {
      const isValidated = validateData(
        data.condition,
        stateData[`${data.name}`]
      );
      if (!isValidated) errorFields.push(data);
      if (!isValidated) validFields.push(data);
      stateErrorStateCopy[`${data.name}State`] = isValidated
        ? "has-success"
        : "has-error";
    });

    setStateErrorState(stateErrorStateCopy);
    return { errorFields, validFields };
  };

  const validateStateDataField = (item, value) => {
    const stateErrorStateCopy = { ...stateErrorState };
    stateErrorStateCopy[`${item.name}State`] = validateData(
      item.condition,
      value
    )
      ? "has-success"
      : "has-error";
    setStateErrorState(stateErrorStateCopy);
  };

  const handleChange = (e, item) => {
    const { name, value, checked } = e.target;

    setStateData({
      ...stateData,
      [name]: name === "is_funded" ? checked : value,
    });
    validateStateDataField(item, value);
  };
  const clearFields = () => {
    clearOrGenerateFields();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { errorFields, validFields } = validateStateData();
    if (errorFields.length) return alert("Check for errors in form data");
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}individual_user`, stateData, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      })
      .then((response) => {
        alert(response.data.message);
        clearFields();
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  return (
    <div className="col-lg-12">
      <h2 className="f_p f_600 f_size_24 t_color3 mb_20">
        Sign Up as Individual
      </h2>
      <div className="col-lg-12 skipPLPR">
        {INDIVIDUAL_FIELDS.map((item) => {
          if (!item?.hide)
            return (
              <div className="form-group text_box col-xs-12 col-sm-6 col-lg-4 skipPL mb_5">
                {item.type !== "checkbox" && (
                  <label className="f_p text_c f_400">{item.placeholder}</label>
                )}
                <input
                  onChange={(e) => {
                    handleChange(e, item);
                  }}
                  type={item.type}
                  placeholder={item.placeholder}
                  name={item.name}
                  value={stateData[item.name]}
                />
                {item.type === "checkbox" && (
                  <label class="ff_p text_c f_400 ml_10">
                    {item.placeholder}
                  </label>
                )}
                {stateErrorState[`${item.name}State`] === "has-error" && (
                  <label className="f_p text_c f_400" style={{ color: "red" }}>
                    {item.errorMsg}
                  </label>
                )}
              </div>
            );
        })}
      </div>
      <div className="col-sm-12 text_box">
        <div
          className="mb_15"
          style={{ display: "flex", justifyContent: "space-between" }}
        ></div>

        <button
          type="submit"
          className="btn_three skipMargin"
          onClick={handleSubmit}
        >
          Sign Up
        </button>
      </div>
      <div></div>
    </div>
  );
};
export default IndividualSignup;
