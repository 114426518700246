import React, { useState } from "react";
import FintechSignup from "./FintechSignup";
import IndividualSignup from "./IndividualSignUp";
import LenderSignup from "./LenderSignup";
import SignInInfo from "./SignInInfo";
import SignupTypeSelect from "./SignUpTypeSelect";
const SignUpForm = props => {
  const [type, setType] = useState("fintech");
  return (
    <section className="sign-up-area sign_in_area bg_color sec_pad">
      <section className="sign_in_area bg_color">
        <div className="container sign_info col-lg-12 skipPLPR">
          <div
            className="col-lg-12 displayIB skipPLPR mb_20"
            style={{ borderBottom: "1px solid #ccc" }}
          >
            <SignInInfo />
          </div>
          <div className="col-lg-12 skipPLPR displayIB">
            <SignupTypeSelect
              type={type}
              onTypeChange={event => {
                const { name } = event.target;
                setType(name);
              }}
            />
            {type === "fintech" && <FintechSignup />}
            {type === "lender" && <LenderSignup />}
            {type === "indivisual" && <IndividualSignup />}
          </div>
        </div>
      </section>
    </section>
  );
};
export default SignUpForm;
