import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import BureauPullList from "../components/BureauPull/BureauPullList";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import { Link, NavLink } from "react-router-dom";
const BureauPull = () => (
  <div className="body_wrapper">
    <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />
    <BureauPullList />
    <Footer FooterData={FooterData} />
  </div>
);
export default BureauPull;
