export const LENDER_FIELDS = [
  {
    placeholder: "Username",
    name: "name",
    type: "text",
    component: "text",
    condition: "string",
    errorMsg: "Enter valid Username name",
    isMandatory: true,
  },
  {
    placeholder: "CIN",
    name: "cin",
    type: "text",
    component: "text",
    condition: "cin",
    errorMsg: "Enter valid CIN",
    isMandatory: true,
  },
  {
    placeholder: "Address",
    name: "bank_address",
    type: "text",
    component: "text",
    condition: "bank_addres",
    errorMsg: "Enter valid address",
    isMandatory: true,
  },
  {
    placeholder: "State",
    name: "service_delivery_state",
    type: "text",
    component: "text",
    condition: "service_delivery_state",
    errorMsg: "Enter valid state",
    isMandatory: true,
  },
  {
    placeholder: "City",
    name: "city",
    type: "text",
    component: "text",
    condition: "city",
    errorMsg: "Enter valid city",
    isMandatory: true,
  },
  {
    placeholder: "Pin Code",
    name: "pin_code",
    type: "text",
    component: "text",
    condition: "pincode",
    errorMsg: "Enter valid pincode",
    isMandatory: true,
  },
  {
    placeholder: "GSTIN",
    name: "gstin",
    type: "text",
    component: "text",
    condition: "gstin",
    errorMsg: "Enter valid GSTIN",
    isMandatory: true,
  },
  {
    placeholder: "TIN",
    name: "tin",
    type: "text",
    component: "text",
    condition: "tin",
    errorMsg: "Enter valid TIN",
    isMandatory: true,
  },
  {
    placeholder: "Business No",
    name: "business_phone",
    type: "text",
    component: "text",
    condition: "mobile",
    errorMsg: "Enter valid mobile no",
    isMandatory: true,
  },
  {
    placeholder: "Website URL",
    name: "website",
    type: "text",
    component: "text",
    condition: "url",
    errorMsg: "Enter valid website url with https",
    isMandatory: true,
  },
  //   {
  //     placeholder: "Custom code",
  //     name: "custom_code",
  //     type: "text",
  //     component: "text",
  //     condition: "string",
  //     errorMsg: "Custom code is required",
  //     hide: true,
  //     isMandatory: false,
  //   },
  {
    placeholder: "Directors",
    name: "directors",
    type: "text",
    component: "text",
    condition: "array",
    errorMsg: "Add at least 1 director",
    hide: true,
    isMandatory: true,
  },
];
