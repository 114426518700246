import React, { Component } from "react";
import Featuresitems from "./Featuresitems";

class Features extends Component {
  render() {
    var { aClass } = this.props;
    return (
      <section className={`agency_featured_area bg_color ${aClass}`}>
        <div className="container">
          <h2
            className="f_size_30 f_600 t_color3 l_height40 text-center wow fadeInUp"
            data-wow-delay="0.3s"
          >
            Namaste Fin - Brand by BEIT anything tech pvt ltd wherever your
            <br /> business agency
          </h2>
          <div className="features_info">
            <img
              className="dot_img"
              src={require("../../img/home4/dot.png")}
              alt=""
            />
            <Featuresitems
              rowClass="row flex-row-reverse"
              aClass="pr_70 pl_70"
              fimage="work1.png"
              iImg="icon01.png"
              ftitle="Marketplace for FINTECH and LENDERS"
              descriptions="Discover a world of financial possibilities at Namaste fin. We are your one-stop destination for bridging the gap between innovative fintech solutions and lending institutions, creating an ecosystem that fuels financial growth and collaboration.
"
            />
            <Featuresitems
              rowClass="row agency_featured_item_two"
              aClass="pl_100"
              fimage="work2.png"
              iImg="icon02.png"
              ftitle="Streamlining Borrowing for a Brighter Financial Future"
              descriptions="Unlock the power of efficient lending with Namaste fin in house LOS and LMS system. We provide state-of-the-art loan origination solutions designed to simplify and enhance the borrowing process for both lenders and borrowers, making financial goals more attainable."
            />
            <Featuresitems
              rowClass="row flex-row-reverse"
              aClass="pr_70 pl_70"
              fimage="work3.png"
              iImg="icon3.png"
              ftitle="Who We Are"
              descriptions="We are a dedicated team of experts in the lending and technology space, committed to revolutionizing the way loans are originated. Our cutting-edge loan origination system combines innovation, user-friendliness, and data-driven insights to empower lenders and borrowers alike."
            />
            <div className="dot middle_dot">
              <span className="dot1"></span>
              <span className="dot2"></span>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Features;
