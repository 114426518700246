import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import { Link, NavLink } from "react-router-dom";

const AdditionalInfo = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />
      <section className="error_two_area">
        <div className="container flex">
          <div className="error_content_two text-center">
            <h2>Hello Additional Info</h2>

            <Link className={`navbar-brand`} to="/Enach">
              <span className="about_btn btn_hover">
                to ENACH page <i className="arrow_right"></i>
              </span>
            </Link>
          </div>
        </div>
      </section>
      <Footer FooterData={FooterData} />
    </div>
  );
};

export default AdditionalInfo;
