import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import AgencyBanner from "../components/Banner/AgencyBanner";
import BannerData from "../components/Banner/BannerData";
import MarketingService from "../components/Service/MarketingService";
import ServiceData from "../components/Service/ServiceData";
import AgencyAbout from "../components/About/AgencyAbout";
import Features from "../components/Features/Features";
import MarketingTestimonial from "../components/Testimonial/MarketingTestimonial";
import AgencyAction from "../components/AgencyAction";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import SupportIntegration from "../components/SupportIntegration";

const DigitalMarketing = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />
      <AgencyBanner BannerData={BannerData} />
      {/* <MarketingService ServiceData={ServiceData} /> */}
      {/* <AgencyAbout ServiceData={ServiceData} /> */}
      <Features />
      {/* <MarketingTestimonial BannerData={BannerData} /> */}
      {/* <AgencyAction /> */}
      <SupportIntegration />
      <Footer FooterData={FooterData} />
    </div>
  );
};
export default DigitalMarketing;
