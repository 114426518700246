import React, { useEffect, useState } from "react";
import SeoTitle from "../Title/SeoTitle";
import Fade from "react-reveal/Fade";
import axios from "axios";
import { Link, NavLink, useParams } from "react-router-dom";

const ProductList = () => {
  const { id } = useParams();
  const [products, setProducts] = useState(null);
  useEffect(() => {
    if (!products) fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}get_approved_plt/5656` //for ref - we are going to take cid time being its hardcoded
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <React.Fragment>
      <section className="seo_service_area sec_pad">
        <div className="container">
          <SeoTitle
            Title="Our product offerings"
            TitleP="Select your loan segment to apply for loan"
          />
          <div className="row seo_service_info">
            {products &&
              products.data.map((item, idx) => (
                <Fade bottom duration={500} key={idx}>
                  <div className="col-lg-4 col-md-6">
                    <div className="seo_service_item">
                      <img src={require("../../img/seo/b2b_icon.png")} alt="" />

                      <h4>
                        <Link
                          className={`navbar-brand`}
                          to={`/check-eligibility/5656/${item._id}`}
                        >
                          {item.title}
                        </Link>
                      </h4>

                      <p>
                        {item.description} Hello Customer, Click on link below
                        to apply for loan and check eligibility.
                      </p>
                      <Link
                        className={`navbar-brand`}
                        to={`/check-eligibility/5656/${item._id}`}
                      >
                        <i className="arrow_right"></i>
                      </Link>
                    </div>
                  </div>
                </Fade>
              ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ProductList;
