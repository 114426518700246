import React, { useEffect, useState } from "react";
import SeoTitle from "../Title/SeoTitle";
import axios from "axios";
import { useParams } from "react-router-dom";
import Sidenav from "./Sidenav";
import { Grid, Button } from "@mui/material";
import EligibilityCheckFormField from "./EligibilityCheckFormField";
import { validateData } from "../../util/validation";

const EligibilityCheckForm = props => {
  const { company_id, product_loan_type_id } = useParams();
  const [stateData, setStateData] = useState(null);
  const [sidenavItems, setSidenavItems] = useState(null);
  const [fieldDepartmentMapper, setFieldDepartmentMapper] = useState(null);
  const [eligibilityTemplate, setEligibilityTemplate] = useState(null);
  const [validationData, setValidationData] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}check_eligibility_form/${company_id}/${product_loan_type_id}`
      );
      setEligibilityTemplate(response.data.data.data);
      setSidenavItems(response.data.data.sidenavItems);
      setStateData(response.data.data.stateData);
      setValidationData(response.data.data.stateValidationData);
      setFieldDepartmentMapper(response.data.data.fieldDepartmentMapper);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleTextChange = (e, currentItem, currentField) => {
    const { value } = e.target;
    const isValid = validateData(currentItem.type, value);
    setStateData(prevState => ({
      ...prevState,
      [currentField]: value
    }));
    setValidationData(prevState => ({
      ...prevState,
      [`${currentField}State`]: !isValid ? "has-danger" : ""
    }));
  };

  const handleDDChange = (value, currentItem, currentField) => {
    setStateData(prevState => ({
      ...prevState,
      [currentField]: value
    }));
    setValidationData(prevState => ({
      ...prevState,
      [`${currentField}State`]: !value ? "has-danger" : ""
    }));
  };

  const handleCheckBoxChanged = (e, currentItem, currentField) => {
    setStateData(prevState => ({
      ...prevState,
      [currentField]: e.target.checked
    }));
  };

  const validateForm = () => {
    const validationDataCopy = JSON.parse(JSON.stringify(validationData));
    const erroredFields = [];
    eligibilityTemplate.forEach(currentI => {
      const currentF = `${currentI.field}__type__${currentI.type}__component__${currentI.component}`;
      const currentValidation = `${currentI.field}__type__${currentI.type}__component__${currentI.component}State`;
      const isValid = validateData(currentI.type, stateData[currentF]);
      if (!isValid) {
        erroredFields.push(stateData[currentF]);
      }
      validationDataCopy[currentValidation] = !isValid ? "has-danger" : "";
    });
    setValidationData(validationDataCopy);
    return erroredFields.length < 1;
  };

  const handleSubmit = () => {
    const validateFormResp = validateForm();
    if (!validateFormResp) {
      throw {
        message: "Please check all the data ui"
      };
    }
    const submitData = {
      company_id: company_id,
      product_loan_type_id: product_loan_type_id,
      pan_details: "ADDFG3456O",//hard coded
      name:"Suraj Pawar",//hard coded
      phone_number: "9889321563",//hard coded
    };

    eligibilityTemplate.forEach(currentI => {
      const currentF = `${currentI.field}__type__${currentI.type}__component__${currentI.component}`;

      if (currentI.component.toLowerCase() === "text")
        submitData[currentI.field] = stateData[currentF];
      if (currentI.component.toLowerCase() === "dropdown")
        submitData[currentI.field] = stateData[currentF].value;
      if (
        currentI.component.toLowerCase() === "checkbox" ||
        currentI.component.toLowerCase() === "toggle"
      )
        submitData[currentI.field] = stateData[currentF] ? 1 : 0;
    });

    const postData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}check_eligibility_post_borro_data`,
          submitData
        );
        alert(response.data.message);
      } catch (error) {
        alert(error.response.data.message);
      }
    };
    postData();
  };

  return (
    <React.Fragment>
      <section className="seo_service_area sec_pad">
        <div className="container">
          <SeoTitle Title="Check Eligibility" />
          <Grid container xs={12} spacing={1}>
            {sidenavItems && (
              <>
                <Grid item xs={12} md={3}>
                  <Sidenav sidenavItems={sidenavItems} />
                </Grid>
                <Grid container item xs={12} md={9} spacing={1}>
                  {sidenavItems.map((item, index) => (
                    <Grid item xs={12} key={`${item.label}-${index}`}>
                      {fieldDepartmentMapper && (
                        <EligibilityCheckFormField
                          title={item.label}
                          fieldDepartmentMapper={fieldDepartmentMapper}
                          onTextChanged={handleTextChange}
                          onDDChanged={handleDDChange}
                          onCheckBoxChanged={handleCheckBoxChanged}
                          stateData={stateData}
                          validationData={validationData}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  xs={12}
                  marginY={9}
                  item
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="contained"
                    className="pull-right ml-4 mr-3"
                    sx={{
                      color: "#fff",
                      marginRight: "5px"
                    }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </section>
    </React.Fragment>
  );
};

export default EligibilityCheckForm;
