import React from "react";
import SeoTitle from "../Title/SeoTitle";
import Fade from "react-reveal/Fade";

const Service = () => {
  return (
    <React.Fragment>
      <section className="seo_service_area sec_pad">
        <div className="container">
          <SeoTitle
            Title="Our product offerings"
            TitleP="End to end ecosystem for Fintech's and Lenders to share Leads in secure and compliant ecosystem. End to end LOS LMS supported for B2B / single book lending"
          />
          <div className="row seo_service_info">
            <Fade bottom duration={500}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img src={require("../../img/seo/b2b_icon.png")} alt="" />
                  <a href=".#">
                    <h4>Fintech B2B Lenders connect</h4>
                  </a>
                  <p>
                    Are you an Fintech looking fo NBFC's to provide loan to your
                    customers? Are you an NBFC / BANK looking fo B2B channel
                    partners to share there customers loan requirements as leads
                    to you?
                  </p>
                  <a href=".#">
                    <i className="arrow_right"></i>
                  </a>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={700}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img
                    src={require("../../img/seo/credit_underwriting_icon.png")}
                    alt=""
                  />
                  <a href=".#">
                    <h4>Fintech Underwriting</h4>
                  </a>
                  <p>
                    Come onboard choose your product types from predefined list
                    of underwriting grids of NBFC's and request NBFC's to use
                    there lending product underwriting grids to check your
                    customer's eligibility.
                  </p>
                  <a href=".#">
                    <i className="arrow_right"></i>
                  </a>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={700}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img src={require("../../img/seo/credit_grid.png")} alt="" />
                  <a href=".#">
                    <h4>Predefined credit grids from NBFC's</h4>
                  </a>
                  <p>
                    Come onboard as Lender define your product types so Fintech
                    / DSA's / Branches can use your lending product underwriting
                    grids to check there customer's eligibility.
                  </p>
                  <a href=".#">
                    <i className="arrow_right"></i>
                  </a>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img
                    src={require("../../img/seo/loan_origination_icon.png")}
                    alt=""
                  />
                  <a href=".#">
                    <h4>LOS - SAAS Based Loan Origination System</h4>
                  </a>
                  <p>
                    If you just looking for End to End digital LOS / LMS we have
                    that ready to be served as SAAS. Use our SAAS version of LOS
                    LMS at truly optimized pricing with all updates available
                    for same cost..
                  </p>
                  <a href=".#">
                    <i className="arrow_right"></i>
                  </a>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img src={require("../../img/seo/white-label.png")} alt="" />
                  <a href=".#">
                    <h4>White labeled LOS</h4>
                  </a>
                  <p>
                    If you just looking for End to End digital LOS / LMS we have
                    that ready to be deployed as white labeled solution deployed
                    at our premises. Use our White labeled version of LOS LMS on
                    truly optimized pricing with all updates available for same
                    cost.
                  </p>
                  <a href=".#">
                    <i className="arrow_right"></i>
                  </a>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img src={require("../../img/seo/on_premise.png")} alt="" />
                  <a href=".#">
                    <h4>On premises LOS/LMS</h4>
                  </a>
                  <p>
                    Contact us for your customized requirements. We offer Our
                    LOS/LMS software as a deployed solution on your hardware. We
                    help you end to end install, operate, maintain and enhance
                    the software modules based on your requirements{" "}
                  </p>
                  <a href=".#">
                    <i className="arrow_right"></i>
                  </a>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <section className="seo_features_one sec_pad">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-6">
              <div className="seo_features_img">
                <div className="round_circle"></div>
                <div className="round_circle two"></div>
                <img src={require("../../img/seo/features_img.png")} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <Fade bottom cascade>
                <div className="seo_features_content">
                  <h2>Get started quickly as Fintech / DSA</h2>
                  <p>
                    Gain access to wide pool of lending products. Our onboard
                    Lenders are all compliant by using our solutions.
                  </p>
                  <div className="media seo_features_item">
                    <div className="icon">
                      <img src={require("../../img/seo/icon4.png")} alt="" />
                    </div>
                    <div className="media-body">
                      <h3>Onboarding</h3>
                      <p>
                        Easy onboarding in single day and get access to lending
                        grids by loan types.
                      </p>
                    </div>
                  </div>
                  <div className="media seo_features_item">
                    <div className="icon two">
                      <img src={require("../../img/seo/icon3.png")} alt="" />
                    </div>
                    <div className="media-body">
                      <h3>Subscribe to lenders products</h3>
                      <p>
                        You subscribe for the lenders products and we will get
                        it approved from lenders for you.
                      </p>
                    </div>
                  </div>
                  <div className="media seo_features_item">
                    <div className="icon two">
                      <img src={require("../../img/seo/icon3.png")} alt="" />
                    </div>
                    <div className="media-body">
                      <h3>Start business in single day</h3>
                      <p>
                        Onboarding and engagements between Fintechs and lenders
                        are so fast.
                      </p>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section className="seo_features_one sec_pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="seo_features_img seo_features_img_two">
                <div className="round_circle"></div>
                <div className="round_circle two"></div>
                <img
                  src={require("../../img/seo/features_img_two.png")}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <Fade bottom cascade>
                <div className="seo_features_content">
                  <h2 className="wow fadeInUp">
                    Onboard as Lender and get supported by fintechs for customer
                    flow.
                  </h2>
                  <h6 className="wow fadeInUp">
                    Use our RBI regulation compliant LOS to create Products and
                    underwriting grids.
                  </h6>
                  <p className="wow fadeInUp">
                    We have integrates leading TSP in order to support
                    compliance needs. We help you automate your underwriting
                    process which make sure keeping loans healthy and increase
                    the profitability.
                  </p>
                  <a
                    href=".#"
                    className="seo_btn seo_btn_one btn_hover wow fadeInUp"
                  >
                    Learn More
                  </a>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Service;
