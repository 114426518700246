import React from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { Box, Typography } from "@mui/material";

const Sidenav = props => {
  const { sidenavItems } = props;
  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <Box key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <Typography
          component="a"
          href={`#${href}`}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({ transitions }) => ({
            display: "flex",
            alignItems: "center",

            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter
            })
          })}
        >
          <Box mr={1.5} lineHeight={1} color={"white"}>
            <Icon fontSize="small">{icon}</Icon>
          </Box>
          {label}
        </Typography>
      </Box>
    );
  });

  return (
    <Card
      sx={{
        position: "sticky",
        top: "1%"
      }}
    >
      <Box
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </Box>
    </Card>
  );
};

export default Sidenav;
