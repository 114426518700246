import React, { useState } from "react";

const SequrityPrice = () => {
  const [list, setList] = useState([
    {
      id: 3,
      adTitle: "Marketplace access",
      icon: "icon_check_alt2",
      icon2: "icon_check_alt2",
      icon3: "icon_check_alt2",
      icon4: "icon_check_alt2",
    },
    {
      id: 4,
      adTitle: "Register with lenders",
      icon: "icon_check_alt2",
      icon2: "icon_check_alt2",
      icon3: "icon_check_alt2",
      icon4: "icon_check_alt2",
    },
    {
      id: 5,
      adTitle: "SAAS SHARED DB",
      icon: "icon_close",
      icon2: "icon_check_alt2",
      icon3: "icon_close",
      icon4: "icon_close",
    },
    {
      id: 6,
      adTitle: "On Premise DB",
      icon: "icon_close",
      icon2: "icon_close",
      icon3: "icon_check_alt2",
      icon4: "icon_check_alt2",
    },
    {
      id: 1,
      adTitle: "Custom products",
      icon: "icon_close",
      icon2: "icon_close",
      icon3: "icon_check_alt2",
      icon4: "icon_check_alt2",
    },
    {
      id: 2,
      adTitle: "Onboard Fintech",
      icon: "icon_close",
      icon2: "icon_close",
      icon3: "icon_check_alt2",
      icon4: "icon_check_alt2",
    },
    {
      id: 7,
      adTitle: "Onboard Lender",
      icon: "icon_close",
      icon2: "icon_close",
      icon3: "icon_check_alt2",
      icon4: "icon_check_alt2",
    },
  ]);
  return (
    <section className="pricing_area_four sec_pad">
      <div className="container">
        <div className="hosting_title security_title text-center">
          <h4 className="wow fadeInUp" data-wow-delay="0.2s">
            <span>NamasteFin - Marketplace, LOS/LMS for Fintech - Lenders</span>
            <p>- By BEIT anything tech pvt ltd.</p>
          </h4>
        </div>
        <div className="price_info_two price_info_three">
          <div className="price_head">
            <div className="p_head time">
              <h4>Features</h4>
            </div>
            <div className="p_head">
              <h5>Fintech - NBFC connect</h5>
              <p>Pay as you go</p>
            </div>
            <div className="p_head">
              <h5>Pure SAAS LOS-LMS</h5>
              <p> ₹50k / mo onwards*</p>
            </div>
            <div className="p_head">
              <h5>White labeled - LOS-LMS</h5>
              <p> ₹1-lakh / mo onwards*</p>
            </div>
            <div className="p_head">
              <h5>On Premise custom LOS-LMS</h5>
              <p> Setup a meeting*</p>
            </div>
          </div>
          <div className="price_body">
            {list.map((item) => {
              return (
                <div className="pr_list" key={item.id}>
                  <div className="price_item">
                    <h5
                      className="pr_title"
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      data-original-title="Save time by using keyboard shortcuts all across SaasLand"
                    >
                      {item.adTitle}
                    </h5>
                  </div>
                  <div
                    className="price_item"
                    data-title="Fintech - NBFC connect (Pay as you go*)"
                  >
                    <h5 className="check">
                      <i className={item.icon}></i>
                    </h5>
                  </div>
                  <div
                    className="price_item"
                    data-title="Pure SAAS LOS-LMS (₹50k/mo onwards*)"
                  >
                    <h5 className="check">
                      <i className={item.icon2}></i>
                    </h5>
                  </div>
                  <div
                    className="price_item"
                    data-title="White labeled - LOS-LMS (₹1-lakh/mo onwards*)"
                  >
                    <h5 className="check">
                      <i className={item.icon3}></i>
                    </h5>
                  </div>
                  <div
                    className="price_item"
                    data-title="On Premise custom LOS-LMS (Setup a meeting*)"
                  >
                    <h5 className="check">
                      <i className={item.icon4}></i>
                    </h5>
                  </div>
                </div>
              );
            })}
            <div className="pr_list">
              <div className="price_item"></div>
              <div className="price_item">
                <a href="/#" className="price_btn btn_hover">
                  Choose This
                </a>
              </div>
              <div className="price_item">
                <a href="/#" className="price_btn btn_hover">
                  Choose This
                </a>
              </div>
              <div className="price_item">
                <a href="/#" className="price_btn btn_hover">
                  Choose This
                </a>
              </div>
              <div className="price_item">
                <a href="/#" className="price_btn btn_hover">
                  Choose This
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SequrityPrice;
