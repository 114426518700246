import React, { Component } from "react";
import Sectitle from "./Title/Sectitle";

class SupportIntegration extends Component {
  constructor() {
    super();
    this.state = {
      integrationItem: [
        {
          id: 100,
          iImage: "digitap.png",
          text: "DIGITAP"
        },
        {
          id: 101,
          iImage: "finvu.png",
          text: "FINVU AA"
        },
        {
          id: 102,
          iImage: "signzy.png",
          text: "SIGNZY"
        },
        {
          id: 102,
          iImage: "other.png",
          text: "Many others"
        }
      ]
    };
  }

  render() {
    return (
      <section className="support_integration_area">
        <div className="container">
          {/* <Sectitle
            sClass="sec_title text-center mb_70"
            Title="Third party Integrations"
            TitleP="We specialize in third-party customized service integration. Our mission is to empower LOS, LMS with the ability to seamlessly connect and optimize systems, validate borrowers and help lenders underwrite better, creating a unified and efficient ecosystem. With our expertise, your lending journey can harness the full potential of integrated services tailored to your unique needs."
          /> */}
          <h3 className="sec_title text-center mt_40 mb_20">
            Third party Integrations
          </h3>
          <p className="sec_title text-center mb_70">
            We specialize in third-party customized service integration. Our
            mission is to empower LOS, LMS with the ability to seamlessly
            connect and optimize systems, validate borrowers and help lenders
            underwrite better, creating a unified and efficient ecosystem. With
            our expertise, your lending journey can harness the full potential
            of integrated services tailored to your unique needs.
          </p>
          <div className="row flex-row-reverse">
            <div className="col-lg-9 col-md-10 col-sm-12">
              <div className="row">
                {this.state.integrationItem.map(post => (
                  <div className="col-lg-4 col-md-4 col-sm-6" key={post.id}>
                    <a href="/#" className="s_integration_item">
                      <img
                        src={require("../img/tsp/" + post.iImage)}
                        alt=""
                        style={{ width: "100px", height: "100px" }}
                      />
                      <h5>{post.text}</h5>
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-3 col-md-2 col-sm-12">
              <img
                className="integration_img"
                src={require("../img/new-home/tree.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default SupportIntegration;
