import React from "react";
import Select from "react-select";
import { withStyles } from "@mui/styles";
import { Button } from "reactstrap";
import { Typography } from "@mui/material";

const styles = () => ({
  selector: {
    width: "100% !important",
    color: "#1e1e2f"
  },
  mainContainer: {
    display: "flex"
  },
  buttonContainer: {
    marginTop: "5px"
  }
});

class CustomDropdown extends React.Component {
  handleDropdownChange = value => {
    const { handleDropdownChange } = this.props;
    handleDropdownChange(value);
  };

  handleRefreshList = () => {
    const { handleRefreshList } = this.props;
    handleRefreshList();
  };

  render() {
    const {
      classes,
      isRefresh,
      placeholder,
      data,
      value,
      isMulti,
      isDisabled,
      isClearable,
      error,
      helperText
    } = this.props;
    return (
      <>
        <div className={classes.mainContainer}>
          <div className={classes.selector}>
            <Select
              isMulti={isMulti}
              name="type"
              value={value}
              isDisabled={isDisabled}
              onChange={valueData => this.handleDropdownChange(valueData)}
              options={data}
              isClearable={isClearable}
              placeholder={placeholder}
            />
            {error && (
              <Typography
                variant="h6"
                component="p"
                style={{
                  fontSize: "12px",
                  color: "#F44335",
                  fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
                  fontWeight: "300"
                }}
              >
                {helperText}
              </Typography>
            )}
          </div>
          {isRefresh ? (
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="info"
                className="tim-icons icon-refresh-02"
                size="sm"
                onClick={() => this.handleRefreshList()}
              />
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CustomDropdown);
