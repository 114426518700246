import React, { Component } from "react";
import FooterData from "../Footer/FooterData";

class Teamitem extends Component {
  render() {
    var { teamImage, memberN, memberd, memberSP, memberlnk } = this.props;
    return (
      <div className="ex_team_item">
        <img
          src={require("../../img/teams/" + teamImage)}
          alt="team"
          style={{ width: "100%", height: "100%" }}
        />
        <div className="team_content">
          <a href=".#">
            <h3 className="f_p f_size_16 f_600 t_color3">{memberN}</h3>
          </a>
          <h5>{memberd}</h5>
          <h5>{memberSP}</h5>
        </div>
        <div className="hover_content">
          <div className="n_hover_content">
            <ul className="list-unstyled">
              {FooterData.socialIcon.map((item) => {
                return null;
                {
                  /* return(
                                        <li key={item.id}><a href={item.url}><i className={`${item.icon}`}></i></a></li>
                                    ) */
                }
              })}
            </ul>
            <div className="br"></div>
            <a href={memberlnk} target="_blank">
              <h3
                className="f_p f_size_16 f_600 w_color"
                style={{ cursor: "pointer" }}
              >
                {memberN}
              </h3>
            </a>
            <h5>{memberd}</h5>
            <h5>{memberSP}</h5>
          </div>
        </div>
      </div>
    );
  }
}
export default Teamitem;
