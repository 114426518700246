export const EligibilityCheckData = {
    incomeTypeOptions: [
      {
        value: "Salaried",
        label: "Salaried",
      },
      {
        value: "Self Employed",
        label: "Self Employed",
      },
      {
        value: "Business",
        label: "Business",
      },
    ],
  
    statesOptions: [
      {
        value: "Maharashtra",
        label: "Maharashtra",
      },
      {
        value: "Goa",
        label: "Goa",
      },
      {
        value: "Bihar",
        label: "Bihar",
      },
      {
        value: "Gujarat",
        label: "Gujarat",
      },
      {
        value: "Telangana",
        label: "Telangana",
      },
    ],
    citiesOptions: [
      {
        value: "Pune",
        label: "Pune",
      },
      {
        value: "Solapur",
        label: "Solapur",
      },
      {
        value: "Mumbai",
        label: "Mumbai",
      },
      {
        value: "Panaji",
        label: "Panaji",
      },
      {
        value: "Tirupati",
        label: "Tirupati",
      },
      {
        value: "Barshi",
        label: "Barshi",
      },
      {
        value: "Phaltan",
        label: "Phaltan",
      },
    ],
    pincodeOptions: [
      {
        value: "413401",
        label: "413401",
      },
      {
        value: "412402",
        label: "412402",
      },
      {
        value: "413409",
        label: "413409",
      },
      {
        value: "415406",
        label: "415406",
      },
      {
        value: "110060",
        label: "110060",
      },
      {
        value: "110040",
        label: "110050",
      },
    ],
  };
  