import React, { Component, useState } from "react";
import StudySlider from "../components/StudySlider";
import SeoTitle from "../components/Title/SeoTitle";
import Testimonial from "../components/Testimonial/Testimonial";
import Fade from "react-reveal/Fade";
import axios from "axios";
const Subscribe = props => {
  const { FooterData } = props;
  const [name, setName] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [displayError, setDisplayError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const clearFields = () => {
    setName("");
    setCompany_name("");
    setEmail("");
    setMobile("");
  };
  const handleSubmit = async e => {
    e.preventDefault();
    setDisplayError(false);
    setErrorMsg("");
    setDisplaySuccess(false);
    setSuccessMsg("");

    const dataToSubmit = {
      name,
      email,
      company_name,
      mobile
    };

    const response = axios
      .post("https://api-staging.namastefin.in/api/enquiry-data", dataToSubmit)
      .then(response => {
        clearFields();
        setSuccessMsg(response.data.message);
        setDisplaySuccess(true);
      })
      .catch(error => {
        setDisplayError(true);
        setErrorMsg(error.response.data.message);
      });
  };

  const change = (e, func) => {
    const { name, value } = e.target;
    func(value);
  };
  return (
    <>
      <section className="seo_subscribe_area">
        <div className="overlay_img"></div>
        <div className="cloud_img">
          <img src={require("../img/seo/cloud.png")} alt="" />
        </div>
        <div className="container">
          <SeoTitle
            Title="Request a demo"
            TitleP="Want to know more about our offerings, product? Get in touch with our support team by filling up little info about your company"
          />
          <form
            action="#"
            onSubmit={handleSubmit}
            className="row seo_subscribe_form"
          >
            <div
              className="input-group col-lg-5 col-md-4 col-sm-6"
              style={{ marginBottom: "5px" }}
            >
              <input
                type="text"
                name="name"
                id="name"
                value={name}
                placeholder="Name"
                className="form-control"
                onChange={e => change(e, setName)}
              />
            </div>
            <div
              className="input-group col-lg-5 col-md-4 col-sm-6"
              style={{ marginBottom: "5px" }}
            >
              <input
                type="text"
                name="c-name"
                id="c-name"
                value={company_name}
                placeholder="Company Name"
                className="form-control"
                onChange={e => change(e, setCompany_name)}
              />
            </div>
            <div
              className="input-group col-lg-5 col-md-4 col-sm-6"
              style={{ marginBottom: "5px" }}
            >
              <input
                type="number"
                name="mobile"
                id="mobile"
                value={mobile}
                placeholder="Mobile"
                className="form-control"
                onChange={e => change(e, setMobile)}
              />
            </div>
            <div className="input-group col-lg-5 col-md-4 col-sm-6">
              <input
                type="email"
                name="email"
                id="emails"
                value={email}
                placeholder="Email"
                className="form-control"
                onChange={e => change(e, setEmail)}
              />
            </div>
            <div className="input-group col-lg-2 col-md-3 col-sm-12">
              <input
                type="submit"
                name="submit"
                value="Submit"
                className="check-btn"
              />
            </div>
          </form>
          {displayError && <p style={{ color: "red" }}>{errorMsg}</p>}
          {displaySuccess && <p style={{ color: "white" }}>{successMsg}</p>}
        </div>
      </section>
      {/* <section className="case_studies_area sec_pad">
        <div className="container">
          <SeoTitle
            Title="Our case studies"
            TitleP="The full monty burke posh excuse my French Richard cheeky bobby spiffing crikey Why gormless, pear shaped.!"
          />
          <StudySlider />
        </div>
      </section>
      <section className="seo_fact_area sec_pad">
        <div className="home_bubble">
          <div className="bubble b_one"></div>
          <div className="bubble b_three"></div>
          <div className="bubble b_four"></div>
          <div className="bubble b_six"></div>
          <div
            className="triangle b_eight"
            data-parallax='{"x": 120, "y": -10}'
          >
            <img src="img/seo/triangle_one.png" alt="" />
          </div>
        </div>
        <div className="container">
          <SeoTitle Title="Over 1200+ completed loans & Still counting." />
          <div className="seo_fact_info">
            <div className="seo_fact_item">
              <div className="text">
                <div className="counter one">693</div>
                <p>Happy Clients</p>
              </div>
            </div>
            <div className="seo_fact_item">
              <div className="text">
                <div className="counter two">276</div>
                <p>Projects</p>
              </div>
            </div>
            <div className="seo_fact_item">
              <div className="text">
                <div className="counter three">102</div>
                <p>SEO Winners</p>
              </div>
            </div>
            <div className="seo_fact_item last">
              <div className="text">
                <div className="counter four">93</div>
                <p>Experience</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <Testimonial tClass="testimonial_area sec_pad" FooterData={FooterData} /> */}
      {/* <section className="seo_partner_logo_area sec_pad">
        <div className="container">
          <div className="seo_sec_title text-center mb_70">
            <Fade bottom>
              <h2>
                Relied on marketers, trusted by engineers,
                <br /> and beloved by executives, everyw here.
              </h2>
            </Fade>
          </div>
          <div className="partner_logo_area_four">
            <div className="row partner_info">
              <div className="logo_item">
                <a href=".#">
                  <img src={require("../img/seo/logo_01.png")} alt="" />
                </a>
              </div>
              <div className="logo_item">
                <a href=".#">
                  <img src={require("../img/seo/logo_02.png")} alt="" />
                </a>
              </div>
              <div className="logo_item">
                <a href=".#">
                  <img src={require("../img/seo/logo_03.png")} alt="" />
                </a>
              </div>
              <div className="logo_item">
                <a href=".#">
                  <img src={require("../img/seo/logo_04.png")} alt="" />
                </a>
              </div>
              <div className="logo_item">
                <a href=".#">
                  <img src={require("../img/seo/logo_05.png")} alt="" />
                </a>
              </div>
              <div className="logo_item">
                <a href=".#">
                  <img src={require("../img/seo/logo_06.png")} alt="" />
                </a>
              </div>
              <div className="logo_item">
                <a href=".#">
                  <img src={require("../img/seo/logo_07.png")} alt="" />
                </a>
              </div>
              <div className="logo_item">
                <a href=".#">
                  <img src={require("../img/seo/logo_08.png")} alt="" />
                </a>
              </div>
              <div className="logo_item">
                <a href=".#">
                  <img src={require("../img/seo/logo_09.png")} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="seo_call_to_action_area sec_pad">
        <div className="container">
          <div className="seo_call_action_text">
            <h2>
              Ready to get started?
              <br /> lt's fast, free and very easy!
            </h2>
            <a href="/" className="about_btn">
              Get a free Estimate
            </a>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Subscribe;
