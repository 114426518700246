import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import BookLeadForm from "../components/BookLead/BookLeadForm";

const BookLead = () => (
  <div className="body_wrapper">
    <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />
    <BookLeadForm />
    <Footer FooterData={FooterData} />
  </div>
);

export default BookLead;
