import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import SequrityPrice from "../components/Features/SecurityPrice";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";

const Price = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />
      {/* <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Pricing Plan"
        Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"
      /> */}
      <SequrityPrice />
      <Footer FooterData={FooterData} />
    </div>
  );
};
export default Price;
