import React from "react";
import Sectitle from "../Title/Sectitle";
import Teamitem from "../Team/Teamitem";
const Team = () => {
  return (
    <section className="experts_team_area sec_pad">
      <div className="container">
        <div className="support_home_img wow fadeInUp" data-wow-delay="0.9s">
          <img src={require("../../img/new-home/banner.png")} alt="" />
        </div>
        <Sectitle
          sClass="sec_title text-center mb_70"
          Title="Brains behind engine."
          tClass="t_color3"
          TitleP="At the heart of our company's success lies our dynamic and dedicated Software Product Team. We're a diverse group of creative minds, tech enthusiasts, and problem solvers, united by a common goal: to craft exceptional software products that redefine industries and empower our clients."
        />
        <div className="row">
          {/* <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="shital_ceo.png"
              memberN="Shital Dhore"
              memberd="Founder CEO"
            />
          </div> */}
          {/* <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="rohit_cto.png"
              memberN="Rohit Dhore"
              memberd="Founder CTO"
            />
          </div> */}

          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="team_rajeev_singh.png"
              memberN="Rajeev Singh"
              memberd="Chief Information Officer"
              memberSP="IIT (BHU), Varanasi"
              memberlnk="https://www.linkedin.com/in/rajeevsingh8/"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="vivek_cto_2.png"
              memberN="Vivek Nair"
              memberd="Chief Technology Officer"
              memberSP="CTO"
              memberlnk="https://www.linkedin.com/in/deadbrain/"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="nagraj.png"
              memberN="Nagraj Shetty"
              memberd="Compliance Advisory"
              memberSP="CRO"
              memberlnk="https://www.linkedin.com/in/nagrajshetty1983/"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Team;
