import React, { Component, useState } from "react";
import SeoTitle from "../Title/SeoTitle";
import axios from "axios";

const BookLeadForm = props => {
  const { FooterData } = props;
  const [formData, setFormData] = useState({
    partner_loan_app_id: "",
    partner_borrower_id: "",
    first_name: "",
    last_name: "",
    resi_addr_ln1: "",
    city: "",
    state: "",
    pincode: "",
    per_pincode: "",
    appl_phone: "",
    appl_pan: "",
    dob: "",
    gender: "",
    borro_bank_name: "",
    borro_bank_acc_num: "",
    passport: "",
    religion: ""
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [bearerToken, setBearerToken] = useState(
    process.env.REACT_APP_API_BEARER_TOKEN
  );
  const [displayError, setDisplayError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleClear = () => {
    setFormData({
      partner_loan_app_id: "",
      partner_borrower_id: "",
      first_name: "",
      last_name: "",
      resi_addr_ln1: "",
      city: "",
      state: "",
      pincode: "",
      per_pincode: "",
      appl_phone: "",
      appl_pan: "",
      dob: "",
      gender: "",
      borro_bank_name: "",
      borro_bank_acc_num: "",
      passport: "",
      religion: ""
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setDisplayError(false);
    setErrorMsg("");
    setDisplaySuccess(false);
    setSuccessMsg("");

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}application_onboarding`, [formData], {
        headers: {
          Authorization: `Bearer ${bearerToken}`
        }
      })
      .then(response => {
        setResponseMessage(response.data.message);
      })
      .catch(error => {
        console.log(error);
        setResponseMessage("Error: " + error.message);
      });
    handleClear();
  };

  return (
    <React.Fragment>
      <section className="seo_service_area sec_pad">
        <div className="container">
          <SeoTitle
            Title="Book Lead"
            // TitleP="Want to know more about our offerings, product? Get in touch with our support team by filling up little info about your company"
          />
          <form
            action="#"
            onSubmit={handleSubmit}
            className="row seo_subscribe_form row g-3"
          >
            <div className="col-md-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="partner_loan_app_id"
                id="partner_loan_app_id"
                value={formData.partner_loan_app_id}
                placeholder="Partner Loan App Id"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="partner_borrower_id"
                id="partner_borrower_id"
                value={formData.partner_borrower_id}
                placeholder="Partner Borrower Id"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="first_name"
                id="first_name"
                value={formData.first_name}
                placeholder="First Name"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="last_name"
                id="last_name"
                value={formData.last_name}
                placeholder="Last Name"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="resi_addr_ln1"
                id="resi_addr_ln1"
                value={formData.resi_addr_ln1}
                placeholder="Resident Addr. Line"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="city"
                id="city"
                value={formData.city}
                placeholder="City Name"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="state"
                id="state"
                value={formData.state}
                placeholder="Sate Name"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="pincode"
                id="pincode"
                value={formData.pincode}
                placeholder="Pincode"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="per_pincode"
                id="per_pincode"
                value={formData.per_pincode}
                placeholder="Pincode"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="appl_phone"
                id="appl_phone"
                value={formData.appl_phone}
                placeholder="Contact No."
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="appl_pan"
                id="appl_pan"
                value={formData.appl_pan}
                placeholder="Pan No."
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="dob"
                id="dob"
                value={formData.dob}
                placeholder="Date Of Birth"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="gender"
                id="gender"
                value={formData.gender}
                placeholder="Gender"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="borro_bank_name"
                id="borro_bank_name"
                value={formData.borro_bank_name}
                placeholder="Bank Name"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="borro_bank_acc_num"
                id="borro_bank_acc_num"
                value={formData.borro_bank_acc_num}
                placeholder="Back Acc No."
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="passport"
                id="passport"
                value={formData.passport}
                placeholder="Passport"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: "5px" }}>
              <input
                type="text"
                name="religion"
                id="religion"
                value={formData.religion}
                placeholder="Religion"
                className="form-control"
                onChange={handleChange}
              />
            </div>

            <div className="col-12">
              <input
                type="submit"
                name="submit"
                value="Submit"
                className="btn_get btn_hover"
                style={{ color: "#fff", backgroundColor: "#6754e2" }}
              />
            </div>
          </form>
          {displayError && <p style={{ color: "red" }}>{errorMsg}</p>}
          {displaySuccess && <p style={{ color: "white" }}>{successMsg}</p>}
        </div>
      </section>
    </React.Fragment>
  );
};

export default BookLeadForm;
