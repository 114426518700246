import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import EligibilityCheckForm from "../components/CheckEligibility/EligibilityCheckForm";

const CheckEligibility = () => (
  <div className="body_wrapper">
    <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />
    <EligibilityCheckForm />
    <Footer FooterData={FooterData} />
  </div>
);

export default CheckEligibility;
