export const FINTECH_FIELDS = [
  {
    placeholder: "Organization Name",
    name: "name",
    type: "text",
    component: "text",
    condition: "string",
    errorMsg: "Enter valid organization name",
    isMandatory: true
  },
  {
    placeholder: "Address",
    name: "company_address",
    type: "text",
    component: "text",
    condition: "address",
    errorMsg: "Enter valid address",
    isMandatory: true
  },
  {
    placeholder: "Mobile no",
    name: "business_phone",
    type: "text",
    component: "text",
    condition: "mobile",
    errorMsg: "Enter valid mobile no",
    isMandatory: true
  },
  {
    placeholder: "State",
    name: "state",
    type: "text",
    component: "text",
    condition: "state",
    errorMsg: "Enter valid state",
    isMandatory: true
  },
  {
    placeholder: "City",
    name: "city",
    type: "text",
    component: "text",
    condition: "string",
    errorMsg: "Enter valid city",
    isMandatory: true
  },
  {
    placeholder: "Pin Code",
    name: "pin_code",
    type: "text",
    component: "text",
    condition: "pincode",
    errorMsg: "Enter valid pincode",
    isMandatory: true
  },
  {
    placeholder: "TIN",
    name: "tin",
    type: "text",
    component: "text",
    condition: "tin",
    errorMsg: "Enter valid TIN",
    isMandatory: true
  },
  {
    placeholder: "CIN",
    name: "cin",
    type: "text",
    component: "text",
    condition: "cin",
    errorMsg: "Enter valid CIN",
    isMandatory: true
  },
  {
    placeholder: "GSTIN",
    name: "gstin",
    type: "text",
    component: "text",
    condition: "gstin",
    errorMsg: "Enter valid GSTIN",
    isMandatory: true
  },
  {
    placeholder: "Is funded",
    name: "is_funded",
    type: "checkbox",
    component: "checkbox",
    condition: "boolean",
    errorMsg: "please make selection ",
    isMandatory: true
  },
  {
    placeholder: "No loans monthly",
    name: "committed_number",
    type: "text",
    component: "text",
    condition: "number",
    errorMsg: "Enter valid number",
    isMandatory: true
  },
  {
    placeholder: "Minimum ticket size",
    name: "min_ticket_size",
    type: "text",
    component: "text",
    condition: "number",
    errorMsg: "Enter valid number",
    isMandatory: true
  },
  {
    placeholder: "Max ticket size",
    name: "max_ticket_size",
    type: "text",
    component: "text",
    condition: "number",
    errorMsg: "Enter valid number",
    isMandatory: true
  },
  {
    placeholder: "No Loans disbursed",
    name: "no_of_loans_disbursed",
    type: "text",
    component: "text",
    condition: "number",
    errorMsg: "Enter valid number",
    isMandatory: true
  },
  {
    placeholder: "AUM",
    name: "AUM",
    type: "text",
    component: "text",
    condition: "number",
    errorMsg: "Enter valid number",
    isMandatory: true
  },
  {
    placeholder: "Website URL",
    name: "website",
    type: "text",
    component: "text",
    condition: "url",
    errorMsg: "Enter valid website url with https",
    isMandatory: true
  },
  {
    placeholder: "Directors",
    name: "directors",
    type: "text",
    component: "text",
    condition: "array",
    errorMsg: "Add at least 1 director",
    hide: true,
    isMandatory: true
  }
];
