import React from "react";
const SignInInfo = props => {
  return (
    <div className="col-lg-12 skipPLPR">
      <div className="sign_info_content col-lg-12 skipPLPR">
        <h3 className="f_p f_600 f_size_24 t_color3 mb_15">
          Already have an account?
        </h3>
        <h4 className="f_p f_400 f_size_30">
          <button
            type="submit"
            className="btn_three sign_btn_transparent skipMargin"
            onClick={() => {
              window.open("https://dash-staging.namastefin.in/", "_blank");
            }}
          >
            Sign In
          </button>{" "}
          now and start using our
          <span className="f_700"> amazing</span> products.
        </h4>
        <h4 className="f_p f_400 f_size_30">
          OR Go to &nbsp;
          <button
            type="submit"
            className="btn_three sign_btn_transparent skipMargin"
            onClick={() => {
              window.open("https://dash-staging.namastefin.in/", "_blank");
            }}
          >
            Admin Portal
          </button>
          &nbsp;now and start
          <span className="f_700"> managing</span> your offerings.
        </h4>
        <ul className="list-unstyled mb-0">
          <li>
            <i className="ti-check"></i> Premium Access to all Marketplace
          </li>
          <li>
            <i className="ti-check"></i> Create lending products
          </li>
          <li>
            <i className="ti-check"></i> Define credit grid as per your BRE
          </li>
          <li>
            <i className="ti-check"></i> Are you a Fin tech? Request for lending
            product from lenders
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SignInInfo;
