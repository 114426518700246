import React, { useState } from "react";
import Reveal from "react-reveal/Reveal/";
import Player from "./videobtn";

const DesignBanner = () => {
  const [open, setOpen] = useState(false);
  return (
    <section className="seo_home_area">
      <div className="home_bubble">
        <div className="bubble b_one"></div>
        <div className="bubble b_two"></div>
        <div className="bubble b_three"></div>
        <div className="bubble b_four"></div>
        <div className="bubble b_five"></div>
        <div className="bubble b_six"></div>
        <div className="triangle b_seven" data-parallax='{"x": 20, "y": 150}'>
          <img src={require("../../img/seo/triangle_one.png")} alt="" />
        </div>
        <div className="triangle b_eight" data-parallax='{"x": 120, "y": -10}'>
          <img src={require("../../img/seo/triangle_two.png")} alt="" />
        </div>
        <div className="triangle b_nine">
          <img src={require("../../img/seo/triangle_three.png")} alt="" />
        </div>
      </div>
      <div className="banner_top">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center seo_banner_content">
              <Reveal effect="fadeInUp" duration={500}>
                <h2>
                  Seamless{" "}
                  <span className="theme_color-primary">Solutions</span>
                  <br /> for Effortless{" "}
                  <span className="theme_color-secondary">Lending</span>
                </h2>
              </Reveal>
              <Reveal effect="fadeInUp" duration={1000}>
                <p className="wow fadeInUp" data-wow-delay="0.5s">
                  From application submission and verification to credit
                  analysis, approval, disbursement, collections our products
                  empowers your business to make informed decisions swiftly and
                  accurately.
                  <br /> Experience the future of lending with a user-friendly
                  interface, robust automation, and insightful analytics, all
                  aimed at enhancing efficiency, reducing risk, and delivering a
                  superior borrower experience over UI kits and API's. <br />
                  Revolutionize the way you originate loans – making lending
                  smarter, faster, and more secure than ever before
                </p>
              </Reveal>
              <Reveal effect="fadeInLeft" duration={1200}>
                {/* <a
                  href="/SignUp"
                  className="seo_btn seo_btn_one btn_hover wow fadeInLeft"
                >
                  Get Started
                </a> */}
                <div
                  className="video_btn popup-youtube seo_btn seo_btn_one btn_hover wow fadeInLeft"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <div className="icon">
                    <i className="ti-control-play"></i>
                  </div>
                  <span>Watch Video</span>
                </div>
                <Player
                  open={open}
                  toggleModal={() => {
                    setOpen(false);
                  }}
                />
              </Reveal>
              {/* <Reveal effect="fadeInLeft" duration={1200}>
                <a
                  href="./"
                  className="seo_btn seo_btn_two btn_hover wow fadeInRight"
                >
                  Learn More
                </a>
              </Reveal> */}
            </div>
          </div>
          <div className="saas_home_img">
            <Reveal effect="fadeInUp" duration={1400}>
              <img src={require("../../img/seo/image.png")} alt="" />
            </Reveal>
          </div>
        </div>
      </div>
    </section>
  );
};
export default DesignBanner;
