import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import ProductList from "../components/LendingProduct/ProductList";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";

const Products = () => (
  <div className="body_wrapper">
    <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />
    <ProductList />
    <Footer FooterData={FooterData} />
  </div>
);

export default Products;
