import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
const PennyDrop = () => (
  <div className="body_wrapper">
    <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />
    <section className="error_two_area">
      <div className="container flex">
        <div className="error_content_two text-center">
          <h2>Congratulations </h2>
        </div>
      </div>
    </section>
    <Footer FooterData={FooterData} />
  </div>
);
export default PennyDrop;
