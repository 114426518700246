import React from "react";

const TermsOfUse = (props) => {
  return (
    <section className={`container agency_banner_area`}>
      <div className="container">
        <h3>PRIVACY POLICY</h3>
        <p>
          Namastefin brand of BEIT anything tech private limited Private Limited
          and its subsidiaries hereinafter referred as Namastefin is committed
          to respect your privacy and choices while using our website. The
          statement highlights our privacy practices regarding Personal
          Information that we hold about you. ‍
        </p>
      </div>
      <div className="container">
        <h3>A. PERSONAL INFORMATION WE MAY COLLECT AND PROCESS:</h3>
        <p>
          For the purposes of this privacy statement, ‘Personal Information’ is
          any data which relates to an individual who may be identified from
          that data, or from a combination of a set of data, and other
          information which is or may be in possession of NamasteFin.
        </p>
        <p>
          In general, you may browse our website without providing any Personal
          Information about yourself. However, certain information such as your
          IP address, demographics, your computers’ operating system, and
          browser type may be collected in order to provide better usability,
          troubleshooting, site maintenance, and to understand which parts of
          the website are visited and how frequently.
        </p>
        <p>
          When you register on our website or would like to comment on our
          content, we may require you to provide us with certain Personal
          Information such as your name, email address, designation, company,
          country and telephone number, for one or more of the following
          purposes: user ID creation, identifying you, contacting you and
          provide access to desirable content based on your preferences. While
          accessing certain sections of the Namastefin website such as
          Contactus, Demo, Careers and others, you may also be required to
          provide additional Personal Information for Namastefin to process the
          corresponding request. Namastefin will only collect, store, use, and
          share your personal information (including email address) as it is
          defined by our Privacy Policy and as regulated by Indian laws.
        </p>
      </div>
      <div className="container">
        <h3>B. CONSEQUENCES OF NOT PROVIDING PERSONAL INFORMATION:</h3>
        <p>
          If you choose not to provide your Personal Information that is
          mandatory to process your request, we may not be able to provide the
          corresponding service.
        </p>
        <p>
          NamasteFin does not share your Personal Information with third parties
          for marketing purposes without seeking your prior permission.
          NamasteFin will seek your consent prior to using or sharing Personal
          Information for any purpose beyond the requirement for which it was
          originally collected.
        </p>
      </div>
      <div className="container">
        <h3>C. USE OF COOKIES:</h3>
        <h3>Types of cookies used:</h3>
        <h3>Session cookie:</h3>
        <p>
          Session cookies remain only as long as a browsing session is active,
          and are intended to avoid user inconvenience during browsing. These
          cookies allow websites to link the actions of a user during a browser
          session and expire at the end of the browsing session. Session cookies
          also assist the user in navigating the website, and allow the user to
          access secure parts of the webpage when logged in.
        </p>
        <h3>Persistent cookie:</h3>
        <p>
          Persistent cookies are stored on a user’s device even after
          termination of a browsing session. It helps in recalling the
          preferences or actions of the user. Persistent cookies are used for a
          variety of purposes such as retaining the visitor’s language and
          regional preference(s) at the end of each browsing session. We may use
          services of third-party analytics service provider to analyse cookies
          to carry out a behavioural analysis in order to enhance user
          experience and convenience, and also to provide targeted and relevant
          content to visitors. Depending on the type and settings of your
          browser, cookies may be accepted by default. You may change the
          settings of your browser to delete existing cookies or prevent future
          cookies from being automatically accepted. If you disable cookies,
          certain parts and functions of our website may not be available. You
          can learn more about cookies at www.aboutcookies.org.
        </p>
        <h3>Third Party Cookies:</h3>
        <p>
          When you visit NamasteFin websites, our advertisement partners, Google
          and Facebook, whom we have engaged for remarking may introduce
          cookies. Based on your browsing of our website you may see our
          advertisements while browsing through our advertisement partner
          websites and/or their network websites. Such cookies would allow us to
          monitor the effectiveness of the advertisements and to make the
          advertisements more relevant to you. In order to opt out from
          receiving such advertisements, you may set your preferences on our
          partner websites. Alternatively, you may also visit Network
          Advertising Initiative or Digital Advertising Alliance websites to
          opt-out.
        </p>
      </div>
      <div className="container">
        <h3>D. ACCESS, CORRECTION, OBJECTION OF YOUR PERSONAL INFORMATION:</h3>
        <p>
          Depending on local law, you may access, update, or correct your
          Personal Information that we hold, including your profile and
          preferences. You may do so in the corresponding sections where such
          information was provided. If you have difficulty in updating or
          accessing your Personal Information as stated above, you may contact
          us at support@namastefin.in. ‍
        </p>
      </div>
      <div className="container">
        <h3>E. DATA SECURITY:</h3>
        <p>
          NamasteFin adopts reasonable and appropriate security practices and
          procedures that includes administrative, physical security, and
          technical controls in order to safeguard your Personal Information.
        </p>
      </div>
      <div className="container">
        <h3>F. DATA RETENTION:</h3>
        <p>
          NamasteFin may retain your Personal Information as long as there is a
          business requirement, or if otherwise required under applicable laws.
        </p>
      </div>
      <div className="container">
        <h3>G. LINKED WEBSITES:</h3>
        <p>
          NamasteFin may provide links to third-party websites and services.
          However, NamasteFin is not responsible for the privacy statements,
          practices, or the contents of such third-party websites.
        </p>
      </div>
      <div className="container">
        <h3>H. HOW TO CONTACT US:</h3>
        <p>
          If you have any questions regarding our privacy practices or this
          privacy statement, you may contact us at: Email: info@Email:
          info@beitanythingtech.com.com
        </p>
      </div>
      <div className="container">
        <h3>I. UPDATES TO THIS PRIVACY STATEMENT:</h3>
        <p>
          NamasteFin may change the data privacy practices and update this
          privacy statement as and when the need arises, and the same will be
          made available on the website. But our commitment to protect the
          privacy of website users will continue to remain.
        </p>
      </div>
      <div className="container">
        <h3>LIMITED LICENSE:</h3>
        <p>
          Subject to the terms and conditions set forth in these Terms of Use,
          NamasteFin grants you a non-exclusive, non-transferable, limited right
          to access, use and display this Website and the Materials thereon. You
          agree not to interrupt or attempt to interrupt the operation of the
          Website in any manner. Unless otherwise specified, the Website is for
          your personal and non-commercial use. You shall not modify, copy,
          distribute, transmit, display, perform, reproduce, publish, license,
          create derivative works from, transfer, or sell any information,
          software, products or services obtained from this Website.
        </p>
      </div>
      <div className="container">
        <h3>WARRANTIES:</h3>
        <p>
          This website, the information and materials on the site, and any
          software made available on the Website, are provided “as is” without
          any representation or warranty, express or implied, of any kind,
          including, but not limited to, warranties of merchantability,
          non-infringement, or fitness for any particular purpose. There is no
          warranty of any kind, express or implied, regarding third party
          content. In spite of NamasteFin’ best endeavours, there is no warranty
          on behalf of NamasteFin that this Website will be free of any computer
          viruses. Some jurisdictions do not allow for the exclusion of implied
          warranties, so the above exclusions may not apply to you.
        </p>
      </div>
      <div className="container">
        <h3>DISCLAIMER:</h3>
        <p>
          The website may contain inaccuracies and typographical and clerical
          errors. NamasteFin expressly disclaims any obligation(s) to update
          this website or any of the materials on this website. NamasteFin does
          not warrant the accuracy or completeness of the materials or the
          reliability of any advice, opinion, statement or other information
          displayed or distributed through the Website. You acknowledge that any
          reliance on any such opinion, advice, statement, memorandum, or
          information shall be at your sole risk. NamasteFin reserves the right,
          in its sole discretion, to correct any errors or omissions in any
          portion of the Website. NamasteFin may make any other changes to the
          Website, the materials and the products, programs, services or prices
          (if any) described in the Website at any time without notice. This
          Website is for informational purposes only and should not be construed
          as technical advice of any manner. ‍
        </p>
      </div>
      <div className="container">
        <h3>POSH Act:</h3>
        <p>
          As a Company, we are committed to conducting and governing ourselves
          with ethics, transparency, accountability and to this extent, we have
          developed governance structures, practices and procedures to ensure
          ethical conduct at all levels is promoted across our value chain. It
          is thus in acknowledgement of and consonance with these values, that
          we are dedicated to ensuring that the work environment at all our
          locations is conducive to fair, safe and harmonious relations, based
          on mutual trust and respect, between all employees. We at Namastefin
          aim to provide a safe working environment and prohibit any form of
          sexual harassment. Hence any act of sexual harassment or related
          retaliation against or by any employee is unacceptable. The Company
          also has a centralized redressal committee to specifically address any
          complaints of sexual harassment. The Committee is nominated by Head HR
          and headed by a woman employee. If any employee believes that (s)/he
          has been subjected to sexual harassment, such person may file a
          complaint with any member of the committee or send an email to
          incident.alert@namastefin.in. Necessary investigation and action will
          be taken according to the internal policy and guidelines. ‍
        </p>
        <h4>COMMITTEE MEMBERS</h4>
        <p>Mrs. Nishigandha Patil - Chair Person</p>
        <p>Mrs. Sapna Karkhile - Committee Member</p>
        <p>Mr. Abhishek Sawalkar - Committee Member</p>
        <p>Mrs. Nisha Bhapkar - External Committee member</p>
      </div>
    </section>
  );
};
export default TermsOfUse;
