export const INDIVIDUAL_FIELDS = [
  {
    placeholder: "User Name",
    name: "username",
    type: "text",
    component: "text",
    condition: "string",
    errorMsg: "Enter valid user name",
    isMandatory: true,
  },
  {
    placeholder: "Role",
    name: "role",
    type: "text",
    component: "text",
    condition: "string",
    errorMsg: "Enter role",
    isMandatory: true,
  },
  {
    placeholder: "Type",
    name: "Type",
    type: "text",
    component: "text",
    condition: "text",
    errorMsg: "Enter type",
    isMandatory: true,
  },
  {
    placeholder: "Pan",
    name: "pan",
    type: "text",
    component: "text",
    condition: "pan",
    errorMsg: "Enter valid pan",
    isMandatory: true,
  },
  {
    placeholder: "Email",
    name: "email",
    type: "text",
    component: "text",
    condition: "email",
    errorMsg: "Enter valid email",
    isMandatory: true,
  },
  {
    placeholder: "Mobile No",
    name: "mobile_no",
    type: "text",
    component: "text",
    condition: "mobile",
    errorMsg: "Enter valid moblie number",
    isMandatory: true,
  },
  {
    placeholder: "Date of birth",
    name: "dob",
    type: "text",
    component: "text",
    condition: "dob",
    errorMsg: "Enter valid date of birth",
    isMandatory: true,
  },
];
