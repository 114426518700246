import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import AccountAggregatorForm from "../components/AccountAggregator/AccountAggregatorForm";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";

const AccountAggregator = () => (
  <div className="body_wrapper">
    <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />
    <AccountAggregatorForm />
    <Footer FooterData={FooterData} />
  </div>
);

export default AccountAggregator;
